<template>
  <div
    v-loading="isLoading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    :element-loading-background="loadingColor"
  >
    <el-row :gutter="30">
      <el-col
        v-if="IframePermissions('FILTERS', 'EntityGalleryView')"
        :xl="4"
        :lg="4"
        :md="4"
      >
        <div
          v-if="
            showFieldsParent &&
            showFieldsParent.selectedFilter &&
            showFieldsParent.selectedFilter.length > 0
          "
          class="filter-card sidebar-filters"
        >
          <el-scrollbar wrap-style="max-height: calc(100vh - 180px)">
            <el-collapse v-model="activeCol">
              <el-collapse-item name="filters">
                <template slot="title">
                  <span class="collopse-header">Saved Filters</span>
                </template>
                <div class="filters-body">
                  <ul>
                    <li :class="isActive == 'all' ? 'active-filter' : ''">
                      <el-link
                        style="margin-left: 12px !important; margin-top: 5px"
                        :underline="false"
                        :type="isActive == 'all' ? 'danger' : ''"
                        @click="updateFilter('all')"
                        v-if="
                          !isApplicationUserSide ||
                          (isActive &&
                            isActive == 'all' &&
                            isApplicationUserSide)
                        "
                      >
                        All
                      </el-link>
                      <div
                        class="float-right mr-1"
                        v-if="isActive == 'all'"
                        style="margin-top: 4px"
                      >
                        {{ total }}
                      </div>
                    </li>
                    <li
                      v-for="filter in filteredFilters"
                      :key="filter._id"
                      :class="isActive == filter._id ? 'active-filter' : ''"
                    >
                      <el-link
                        style="margin-left: 12px !important; margin-top: 5px"
                        :underline="false"
                        :type="isActive == filter._id ? 'danger' : ''"
                        @click="updateFilter(filter._id)"
                      >
                        {{ filter.filter_name | truncate(17, "...") }}
                      </el-link>
                      <div
                        class="float-right mr-1"
                        v-if="isActive == filter._id"
                        style="margin-top: 4px"
                      >
                        {{ total }}
                      </div>
                    </li>
                  </ul>
                </div>
              </el-collapse-item>
            </el-collapse>
            <div v-if="entityFiltersData && entityFiltersData.quick_filters">
              <quick-entity-filters
                :filterFields="
                  entityFiltersData && entityFiltersData.quick_filters
                    ? entityFiltersData.quick_filters
                    : []
                "
                :allFields="selectedEntityFields"
                :selectedFileds="selectedFileds"
                :showVertically="true"
                @quick-filter-updated="quickFilterUpdated"
              >
              </quick-entity-filters>
            </div>
          </el-scrollbar>
        </div>
        <div
          v-if="
            showFieldsParent &&
            showFieldsParent.selectedFilter &&
            showFieldsParent.selectedFilter.length > 0
          "
          class="sidebar-filters-mobile"
        >
          <el-collapse v-model="activeCol">
            <el-collapse-item name="filters">
              <template slot="title">
                <span class="collopse-header">Saved Filters</span>
              </template>
              <div class="filters-body">
                <ul>
                  <li :class="isActive == 'all' ? 'active-filter' : ''">
                    <el-link
                      style="margin-left: 12px !important; margin-top: 5px"
                      :underline="false"
                      :type="isActive == 'all' ? 'danger' : ''"
                      @click="updateFilter('all')"
                    >
                      All
                    </el-link>
                  </li>
                  <li
                    v-for="filter in filteredFilters"
                    :key="filter._id"
                    :class="isActive == filter._id ? 'active-filter' : ''"
                  >
                    <el-link
                      style="margin-left: 12px !important; margin-top: 5px"
                      :underline="false"
                      :type="isActive == filter._id ? 'danger' : ''"
                      @click="updateFilter(filter._id)"
                    >
                      {{ filter.filter_name | truncate(17, "...") }}
                    </el-link>
                  </li>
                </ul>
              </div>
              <div v-if="entityFiltersData && entityFiltersData.quick_filters">
                <quick-entity-filters
                  :filterFields="
                    entityFiltersData && entityFiltersData.quick_filters
                      ? entityFiltersData.quick_filters
                      : []
                  "
                  :allFields="selectedEntityFields"
                  :selectedFileds="selectedFileds"
                  @quick-filter-updated="quickFilterUpdated"
                >
                </quick-entity-filters>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
      <el-col :xl="lgColSpan" :lg="lgColSpan" :md="20" :sm="24" :xs="24">
        <div class="filter-card" style="overflow-y: hidden">
          <div class="float-right" style="margin-right: 20px">
            <el-tooltip
              class="item"
              effect="dark"
              content="Previous page"
              placement="top-start"
            >
              <el-link
                :disabled="paginationData.page == 1"
                :underline="false"
                type="primary"
                @click="applyPagination('previous')"
                >Previous</el-link
              >
            </el-tooltip>
            {{ paginationData.page }} /
            {{ Math.ceil(paginationData.total / 12) }}
            <el-tooltip
              class="item"
              effect="dark"
              content="Next page"
              placement="top-start"
            >
              <el-link
                :disabled="!paginationData.hasMore"
                :underline="false"
                type="primary"
                @click="applyPagination('next')"
                >Next</el-link
              >
            </el-tooltip>
          </div>
          <br />
          <div style="overflow-y: hidden; width: 80vw">
            <el-scrollbar
              wrap-style="max-height: calc(100vh - 180px); width: 80vw"
            >
              <div class="cards" v-if="data && data.length">
                <div
                  v-for="row in data"
                  :key="row._id"
                  class="entity-data-card"
                  style="overflow-x: hidden"
                >
                  <div
                    v-if="
                      getProfilePicture(row) ||
                      getFieldValue('profile', row) ||
                      getFieldValue('title', row) ||
                      getFieldValue('description', row) ||
                      getFieldValue('field1', row) ||
                      getFieldValue('field2', row) ||
                      getFieldValue('field3', row)
                    "
                  >
                    <div class="card-profile">
                      <img
                        v-if="getProfilePicture(row)"
                        :src="getProfilePicture(row)"
                        style="border-radius: 50%"
                      />
                      <img
                        v-else-if="getFieldValue('profile', row)"
                        :src="getFieldValue('profile', row)"
                        alt="icon"
                        style="border-radius: 50%"
                      />
                      <div v-else>
                        <!-- <i class="el-icon-picture-outline"></i> -->
                        <img
                          v-if="currentEntity.entity_type == 'INDIVIDUAL'"
                          src="@/assets/img/defaultAvatar/Individual.svg"
                          alt="icon"
                          style="border-radius: 50%"
                        />
                        <img
                          v-else
                          src="@/assets/img/defaultAvatar/Business.svg"
                          alt="icon"
                        />
                      </div>
                    </div>
                    <div class="card-body">
                      <p
                        v-if="
                          IframePermissions('VIEW', 'EntityGalleryView') &&
                          getFieldKey('title') &&
                          getFieldValue('title', row)
                        "
                        class="card-title"
                        @click="viewWorkUserData(row)"
                      >
                        {{ getFieldValue("title", row) | truncate(24, "...") }}
                      </p>
                      <p
                        v-if="
                          IframePermissions('VIEW', 'EntityGalleryView') &&
                          getFieldKey('description') &&
                          getFieldValue('description', row)
                        "
                        @click="viewWorkUserData(row)"
                        class="card-descripion header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("description") }} </span
                        >:
                        <span class="card-data">{{
                          getFieldValue("description", row)
                            | truncate(140, "...")
                        }}</span>
                      </p>
                      <p
                        class="card-descripion header"
                        v-if="
                          IframePermissions('VIEW', 'EntityGalleryView') &&
                          getFieldKey('field1') &&
                          getFieldValue('field1', row)
                        "
                        @click="viewWorkUserData(row)"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field1") }} </span
                        >:
                        <span class="card-data">{{
                          getFieldValue("field1", row) | truncate(140, "...")
                        }}</span>
                      </p>
                      <p
                        v-if="
                          IframePermissions('VIEW', 'EntityGalleryView') &&
                          getFieldKey('field2') &&
                          getFieldValue('field2', row)
                        "
                        @click="viewWorkUserData(row)"
                        class="card-descripion header"
                      >
                        <span class="bold-key">
                          {{ getFieldKey("field2") }}
                        </span>
                        :
                        <span class="card-data">{{
                          getFieldValue("field2", row) | truncate(140, "...")
                        }}</span>
                      </p>
                      <p
                        v-if="
                          IframePermissions('VIEW', 'EntityGalleryView') &&
                          getFieldKey('field3') &&
                          getFieldValue('field3', row)
                        "
                        @click="viewWorkUserData(row)"
                        class="card-descripion header"
                      >
                        <span class="bold-key"
                          >{{ getFieldKey("field3") }}
                        </span>
                        :
                        <span class="card-data">{{
                          getFieldValue("field3", row) | truncate(140, "...")
                        }}</span>
                      </p>

                      <br />
                      <template
                        v-if="
                          showFields.action_group_button &&
                          showFields.action_group_button.length
                        "
                      >
                        <div
                          v-for="(
                            action, index
                          ) in showFields.action_group_button"
                          :key="index"
                        >
                          <template v-if="hasHash(action)">
                            <a
                              href="javascript:void(0);"
                              class="card-link"
                              @click="onActionButtonOpen(action, row)"
                              style="
                                color: rgb(64, 158, 255);
                                text-decoration: none;
                              "
                            >
                              {{ getActionButtonLabel(action) }}
                            </a>
                          </template>
                          <template v-else>
                            <a
                              v-if="
                                IframePermissions('VIEW', 'EntityGalleryView')
                              "
                              href="javascript:void(0);"
                              class="card-link"
                              @click="viewWorkUserData(row, action)"
                              style="
                                color: rgb(64, 158, 255);
                                text-decoration: none;
                              "
                            >
                              {{ action }}
                            </a>
                          </template>
                        </div>
                      </template>
                      <el-link
                        v-else-if="
                          IframePermissions('VIEW', 'EntityGalleryView')
                        "
                        type="primary"
                        :underline="false"
                        class="card-link"
                        style="margin-bottom: 10px"
                        @click="viewWorkUserData(row)"
                      >
                        View details
                      </el-link>
                    </div>
                  </div>
                  <el-empty
                    :image-size="100"
                    v-else
                    description="Not configured"
                  ></el-empty>
                </div>
              </div>
              <div v-else>
                <el-empty description="No data"></el-empty>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </el-col>
    </el-row>

    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        :entityData="rowEntityData"
        :selfTemplateId="quickActionDummyField.template_id"
        :form="quickActionDummyForm"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
//mixins
import NavigationHelper from "@/mixins/navigationHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderhelper from "@/mixins/templateBuilderHelper.js";
import { fetchAllEntityFiltersByEntity } from "../../../repo/filtersRepo";
export default {
  props: [
    "entityId",
    "search_string",
    "showFieldsParent",
    "isApplicationUserSide",
    "currentMenu",
    "getSelfEntityFilters",
    "applyFiltersData",
    "keyLabelData",
    "data",
    "entityAllFilters",
    "currentEntity",
    "paginationData",
  ],
  mixins: [
    NavigationHelper,
    entityRelationshipMixin,
    CustomDashboardConfig,
    TemplateBuilderhelper,
  ],
  components: {
    QuickEntityFilters: () =>
      import("@/components/widgets/entityQuickFilters.vue"),
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  mounted() {
    this.entity_id = this.$route.params.entity_id || this.entityId;
    // this.data = this.entitiesData;
    if (this.$route.query.customization) {
      this.customization_id = this.$route.query.customization;
    }
    this.getNecessaryInfo();
    console.log(
      this.currentEntity,
      "this is current entityyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy"
    );
  },
  watch: {
    search_string: async function () {
      this.fetchEntitiesDataForTable(this.applied_quick_filters);
    },
    showFieldsParent: {
      handler: function () {
        if (
          JSON.stringify(this.showFieldsParent) !=
          JSON.stringify(this.showFields)
        ) {
          this.showFields = { ...this.showFieldsParent };
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "getEntityShowFields",
    ]),
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("entityCustomization", [
      "getCustomizationById",
      "getEntityActiveCustomization",
    ]),
    filteredFilters() {
      return this.entityAllFilters.filter((filter) =>
        this.showFieldsParent.selectedFilter.includes(filter._id)
      );
    },
    lgColSpan() {
      return this.showFieldsParent.selectedFilter?.length > 0 ? 20 : 24;
    },
  },
  data() {
    return {
      isLoading: false,
      entity_id: "",
      globalVariablesData: [],
      relationshipsData: [],
      // data: [],
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
      },
      isActive: "all",
      selectedEntityFields: [],
      // currentEntity: {},
      selectedFileds: {},
      applied_quick_filters: [],
      activeCol: ["filters"],
      showFields: {
        profile: "",
        title: "",
        description: "",
        field1: "",
        field2: "",
        field3: "",
        fields: [],
        chart_field: "",
        chart_type: "PIE",
        board_field: "",
        groupBy_chart_field: "",
      },
      loadingText: "Gathering data...",
      loadingColor: "rgba(0, 0, 0, 0.8)",
      limit: 12,
      page: 1,
      total: 0,
      form: {},
      entityUserInfoData: {},
      quickActionButtonType: {
        add: 2,
        assign: 1,
        view: 4,
        add_assign: 3,
      },
      quickActionDummyField: null,
      quickActionDummyForm: {},
      rowEntityData: {},
      quickActionModal: false,
      customization: null,
      customization_id: "",
      applyFilter: {},
      filterData: {},
    };
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, "0");
      const minutes = String(d.getMinutes()).padStart(2, "0");
      const seconds = String(d.getSeconds()).padStart(2, "0");
      return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    },
    applyPagination(action) {
      // if (action == "next") {
      //   this.page++;
      // } else {
      //   if (this.page > 1) {
      //     this.page--;
      //   }
      // }
      // this.fetchEntitiesDataForTable(this.applied_quick_filters);
      this.$emit("refreshPageData", action);
    },
    async viewWorkUserData(row, action = "View") {
      if (row._id) {
        let path = "";

        if (action === "View" || action === "Edit") {
          path = await this.determinePath(action);
        } else {
          this.performAnotherAction(action, row);
          return;
        }
        if (path) {
          let query = this.getNavigationQuery(this.$route.query);
          query = {
            ...query,
            dataId: row._id,
            filter: this.currentFilter,
            menu_id: this.$route.params.menuId,
          };
          if (action === "Edit" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.pageSize = this.$route.query.pageSize || 10;
            query.type = "AUfYWb7SC6U=";
          }
          if (action === "View" && this.isApplicationUserSide) {
            query.layout = "viewType";
            query.page = this.$route.query.page || 1;
            query.type = "AUfYWb7SC6U=";
          }
          this.$router.push({
            path: path,
            query: query,
          });
        }
      }
    },
    async performAnotherAction(action, row) {
      const matchingAction = this.customization?.actions.find(
        (item) => item.label === action
      );
      if (matchingAction) {
        this.quickActionDummyField = {
          label: matchingAction.label,
          parent_entityId: this.currentEntity._id,
          parent_entityDataId: row._id,
          action_button_type: "QUICK_ACTION",
          actions: [
            {
              action_button_action_type: "ESTABLISH_RELATIONSHIP",
              relational_entity_one: matchingAction.relationship,
              action_button_relationship_type:
                this.quickActionButtonType[matchingAction.type],
            },
          ],
          type: matchingAction.type,
          execute: true,
        };
        this.quickActionModal = true;
      }
    },
    async determinePath(action) {
      let path = "";
      if (action === "View") {
        if (this.isApplicationUserSide) {
          path = `/ap/entity-view/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/view/${this.entity_id}`
              : `/entity/view/${this.entity_id}`;
        }
      } else if (action === "Edit") {
        if (this.isApplicationUserSide) {
          path = `/ap/entity-execute/${this.entity_id}`;
        } else {
          path =
            this.getIframeUrlInfo != null
              ? `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`
              : `/entity/edit/${this.entity_id}`;
        }
      }
      return path;
    },
    hexToRgb(hex) {
      hex = hex.replace("#", "");
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);
      return `rgba(${r}, ${g}, ${b}, 0.1)`;
    },
    hasHash(value) {
      return value.includes("#");
    },
    getFieldKey(field) {
      let firstObjectKey = Object.keys(this.keyLabelData)[0];
      if (this.showFields[field]) {
        let [, key] = this.showFields[field].split("#");
        if (this.keyLabelData[firstObjectKey][key]) {
          return this.keyLabelData[firstObjectKey][key];
        }
      }
    },
    getFieldValue(field, data) {
      let firstObject = Object.keys(data?.entityData)[0];
      if (
        "date_time" in data?.entityData[firstObject] &&
        this.showFields[field].split("#")[1] === "date_time" &&
        data?.entityData[firstObject]["date_time"]
      ) {
        const formattedDate = this.formatDate(
          data?.entityData[firstObject]["date_time"]
        );
        return formattedDate;
      }
      if (field == "action_group_button") {
        let quickActions = [];
        if (this.showFields[field]?.length > 0) {
          quickActions = this.showFields[field].filter(
            (item) => !item.includes("#")
          );
          if (quickActions?.length > 0) {
            return quickActions.map((item) => item);
          }
          return "";
        }
      } else if (
        data?.entityData &&
        this.showFields[field] &&
        this.showFields[field].includes("#")
      ) {
        let [temp, key] = this.showFields[field].split("#");
        if (data?.entityData[temp] && data?.entityData[temp][key]) {
          if (data?.entityData[temp][key + "/name"]) {
            return data?.entityData[temp][key + "/name"];
          }
          return data?.entityData[temp][key];
        } else {
          return "";
        }
      } else {
        if (this.currentEntity.entity_type == "INDIVIDUAL") {
          if (
            field == "profile" &&
            data?.avatar &&
            this.showFields[field] == "defaultpic"
          ) {
            return data.avatar;
          }
        }
        return "";
      }
    },
    getActionField(key) {
      let selectedField = this.selectedEntityFields.find((e) => e.key == key);
      selectedField["action_button_type"] = "HYPERLINK";
      selectedField["parent_entityId"] = this.currentEntity._id;
      return selectedField;
    },
    quickFilterUpdated(data) {
      this.applied_quick_filters = [...data];
      this.fetchEntitiesDataForTable(data);
    },
    updateFilter(filter) {
      this.selectedFileds = {};
      if (filter && filter != "all") {
        let entityFiltersData = this.entityAllFilters.find(
          (e) => e._id == filter
        );
        if (entityFiltersData) {
          this.entityFiltersData = { ...entityFiltersData };
          if (this.entityFiltersData.quick_filters) {
            this.entityFiltersData.quick_filters.forEach((e) =>
              this.$set(this.selectedFileds, e, [])
            );
          }
        }
        this.currentFilter = filter;
        this.isActive = filter;
        this.updateCurrentFilter();
      } else {
        this.isActive = "all";
        this.currentFilter = "";
        this.entityFiltersData.quick_filters = [];
        this.resetEntityFiltersData();
        this.fetchEntitiesDataForTable();
      }
    },
    updateCurrentFilter() {
      this.resetEntityFiltersData();
      if (this.currentFilter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        this.entityFiltersData = {
          ...selectedFilter,
          ...{ isSaveFilter: true },
        };
      }
      this.fetchEntitiesDataForTable();
    },
    async getNecessaryInfo() {
      this.isLoading = true;
      if (this.customization_id !== "") {
        await this.$store.dispatch(
          "entityCustomization/fetchCustomizationById",
          this.customization_id
        );
        if (this.getCustomizationById) {
          this.customization = { ...this.getCustomizationById.data };
        }
      } else {
        await this.$store.dispatch(
          "entityCustomization/fetchEntityActiveCustomization",
          this.entity_id
        );
        if (
          this.getEntityActiveCustomization &&
          this.getEntityActiveCustomization.data
        ) {
          this.customization = { ...this.getEntityActiveCustomization.data };
        }
      }
      this.selectedEntityFields = [];
      if (this.currentEntity) {
        if (this.currentEntity?.views_configuration) {
          this.showFields = { ...this.currentEntity.views_configuration };
        } else if (this.showFieldsParent) {
          this.showFields = { ...this.showFieldsParent };
        }
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              let dt = JSON.parse(JSON.stringify(e.templateInfo));
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(dt),
              ];
            }
          });
        }
        // await this.fetchEntitiesDataForTable();
        if (
          this.$route.query?.filter &&
          this.$route.query?.viewType == "CARDS"
        ) {
          this.showFieldsParent.selectedFilter = [this.$route.query.filter];
        }
        if ( this.showFieldsParent.selectedFilter && this.showFieldsParent.selectedFilter.length === 1) {
          this.updateFilter(this.showFieldsParent.selectedFilter[0]);
        }
      }
      this.isLoading = false;
    },
    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFields.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFields.title = standardTemp.template_id + "#" + "name";
            this.showFields.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach((temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFields.profile) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFields.profile = field.key;
                }
              }
              if (!this.showFields.description) {
                let field = (
                  this.getTemplateFields(temp.templateInfo) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFields.description = field.key;
                }
              }
            }
          });
          if (
            !this.showFields.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFields.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
      }
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      return fields;
    },
    async fetchAllEntityFilters(refreshTable) {
      this.isLoading = true;
      const getAllEntityFilters = await fetchAllEntityFiltersByEntity({
        entityId: this.entity_id,
      });
      this.entityAllFilters = [];
      if (getAllEntityFilters) {
        this.isLoading = false;
        this.entityAllFilters = getAllEntityFilters;
        if (this.isApplicationUserSide) {
          this.entityAllFilters = this.entityAllFilters.filter(
            (e) => e._id == this.$route.query.filter
          );
        }
        if (this.$route.query.filter) {
          let entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.$route.query.filter
          );
          if (entityFiltersData) {
            this.entityFiltersData = { ...entityFiltersData };
            this.currentFilter = this.$route.query.filter;
            this.isActive = this.currentFilter;
          } else {
            //removing not existed filter from url
            delete this.$route.query.filter;
            return;
          }
        } else if (refreshTable && this.currentFilter) {
          this.entityFiltersData = this.entityAllFilters.find(
            (e) => e && e._id == this.currentFilter
          );
        }
      } else {
        this.isLoading = false;
      }
    },
    mapDateFilters(filters) {
      return filters.map((v) => {
        let e = JSON.parse(JSON.stringify(v));
        if (e.operator == "real_time" && e.data_type == "DATE") {
          let data = this.getDatePickerHelper1(e.selected_date_picker, e.value);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (
          e.data_type == "DATE_TIME" &&
          e.data_source == "REAL_TIME_PICKER" &&
          e.selected_date_picker &&
          e.operator == "dateinclude"
        ) {
          let data = this.getDatePickerHelper(e.selected_date_picker);
          e.value = data.value;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            e.value = moment(new Date(e.value)).endOf("day");
          }
        }
        return e;
      });
    },
    resetEntityFiltersData() {
      this.entityFiltersData = {
        ...{
          filters: [],
          filter_name: "",
          isSaveFilter: false,
          query_type: "AND",
          quick_filters: [],
          columns_settings: [],
        },
      };
    },
    async fetchEntitiesDataForTable(filters = []) {
      this.isLoading = true;
      this.loadingText = "Gathering " + this.currentEntity.name + " data...";
      if (this.currentFilter) {
        this.isActive = this.currentFilter;
      }
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      if (this.isActive == "all") {
        filters = [...this.entityFiltersData.filters, ...filters];
      } else {
        filters = [
          ...this.entityFiltersData.filters,
          ...this.applyFiltersData.filters,
          ...filters,
        ];
      }
      let params = {
        entity_id: this.entity_id,
        template_fields_data: this.currentEntity.templates.map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(filters || []),
        limit: this.limit,
        page: this.page,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        search_string: this.search_string,
        include_contact_profile: true,
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      this.data = [];
      if (this.getEntityRecordsForTable) {
        this.data = this.mapContactProfile(this.getEntityRecordsForTable.data);
        // this.entityUserInfoData = await this.mapEntityDataWithUserInfo(this.data);
        this.mapEntityDataWithUserInfo(this.data).then((e) => {
          this.entityUserInfoData = { ...e };
        });
        // this.totalData = [...this.data];
        this.total = this.getEntityRecordsForTable.total
          ? this.getEntityRecordsForTable.total
          : 0;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
      this.loadingText = "Gathering data...";
    },
    mapContactProfile(entityData) {
      return entityData.map((el) => {
        if (el?.contact_id?.[0]?.avatar) {
          el.avatar = el?.contact_id?.[0]?.avatar;
        }
        return el;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .sidebar-filters-mobile {
    display: none;
  }
}
@media (max-width: 992px) {
  .sidebar-filters {
    display: none;
  }
}
.bold-key {
  font-weight: 700;
  color: black;
}
.card-body {
  font-family: "Inter";
  font-style: normal;
  // text-align: center;
  align-items: flex-end;

  justify-content: space-between;
  padding-left: 20px;
  .card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #303133;
    cursor: pointer;
  }
  .card-descripion {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #909399;
    display: flex;
  }
  .card-data {
    justify-content: first baseline;
    align-items: center;
    margin-left: 13px;
    width: 150px;
  }
  .card-link {
    font-size: 12px !important;
    margin-top: 10px;
    color: rgb(64, 158, 255);
  }
}
.header {
  font-weight: 600;
  font-size: 30px;
}
.card-profile {
  text-align: center !important;
  img {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .profile-empty {
    margin-top: 15px;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 26%;
    background: #f5f5f5;
    font-size: 75px;
    color: #cdcdcd;
    img {
      margin-top: 25px !important;
    }
  }
}
.entity-data-card {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 10px;
  min-height: 275px;
}
.cards {
  display: grid;
  padding: 0px 20px 20px 20px;
  grid-gap: 1.5em;
}

.el-link.el-link--primary {
  color: var(--primary-color);
}

@media (min-width: 600px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1100px) {
  .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1400px) {
  .cards {
    grid-template-columns: repeat(4, 1fr);
  }
}
.filter-card {
  height: 80vh;
  width: 100% !important;
}
.collopse-header {
  color: #303133 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-left: 20px !important;
}
.filters-body {
  el-link {
    width: 100% !important;
  }
}
.active-filter {
  border-left: 3px solid #f754a2 !important;
  color: #f754a2 !important;
}
</style>
